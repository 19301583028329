import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";

const NavBar = () => {

  const [hideLoginButton, setHideLoginButton] = useState("")
  const [toggleState, setToggleState] = useState(true)
  const [customMargins, setCustomMargins] = useState("-100px")

  useEffect(() => {


    const loginData = localStorage.getItem("credentials")

    if (JSON.parse(loginData) === "wellCorp@admin") {
      setHideLoginButton(true)
    } else {
      setHideLoginButton(false)
    }
  }, [])

  let history = useHistory()
  const sampleCsvUrl = "https://niroggyan.s3.ap-south-1.amazonaws.com/excel_for_portals/samples-wellcorp.zip"

  return (
    <header>
      <Navbar
        className="mb-3"
        bg="primary"
        variant="dark"
        expand="lg"
        collapseOnSelect
      >
        <Container>
          <Link to="/">
            <Navbar.Brand>
              <i className="mb-2 fas fa-home"> Home</i>
            </Navbar.Brand>
          </Link>

          {hideLoginButton ?
            <>
              <Dropdown className="button-focus-css" onClick={() => {
                console.log("toggleState:", customMargins)
                setToggleState(!toggleState)
              }}>
                <Dropdown.Toggle id="dropdown-basic">More Options
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ marginLeft: "-110px" }}>

                  <Dropdown.Item href="#">
                    <Link to="/upload" className="mb-1 text-center">
                      Upload CSV
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item href="#">
                    <Link to={"#"} onClick={() => window.open(sampleCsvUrl)} className="mb-1 text-center">
                        Sample CSV                    
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Link to="/login" onClick={() => localStorage.removeItem("credentials")}>
                      <div>
                        Logout <i className="fas fa-sign-out-alt"></i>
                      </div>
                    </Link>
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </>
            :
            <Link to="/login">
              <div className="text-light">
                <i className="fas fa-user mx-1"></i> Login
              </div>
            </Link>
          }

        </Container>
      </Navbar>
    </header>
  );
};

export default NavBar;