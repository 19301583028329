import React, { useState, useEffect } from "react";
import { Form, Modal, Button, InputGroup } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Avatar from "avataaars";

const HomePage = () => {

  const history = useHistory()

  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [date, setDate] = useState("");
  const [labId, setLabId] = useState("")
  const [empId, setEmpId] = useState("")
  const [bmi, setBmi] = useState("")
  const [bp, setBp] = useState("")
  const [patientEmail, setPatientEmail] = useState("")

  const [patientDetails, setPatientDetails] = useState("");
  const [nextPage, setNextPage] = useState(false);
  const [toggleGender, setToggleGender] = useState(false);
  const [toggleAvatar, setToggleAvatar] = useState(false);
  const [count, setCount] = useState(0);
  const [valueForAvatar, setValueForAvatar] = useState(0);
  const [updatedFields, setUpdatedFields] = useState([])


  const clotheList = [
    "Hoodie",
    "BlazerShirt",
    "BlazerSweater",
    "CollarSweater",
    "GraphicShirt",
    "ShirtVNeck",
  ];

  const accessoriesTypeList = [
    "Sunglasses",
    "Blank",
    "Kurt",
    "Prescription01",
    "Prescription02",
    "Round",
  ];

  // modal state and functions
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const data = localStorage.getItem("patientDetails");
    const data2 = localStorage.getItem("valueForAvatar");
    if (data) {
      setPatientDetails(JSON.parse(data));
      const mydata = JSON.parse(data);
      setGender(mydata.gender);
    }
    if (data2) {
      setValueForAvatar(JSON.parse(data2));
    }
  }, []);

  useEffect(() => {

    const loginData = localStorage.getItem("credentials")
    if (JSON.parse(loginData) === "wellCorp@admin") {
      //      
    } else {
      history.push("/login")
    }
  }, [])

  // useEffect(() => {
  //   localStorage.setItem("patientDetails", JSON.stringify(patientDetails));
  //   localStorage.setItem("valueForAvatar", JSON.stringify(valueForAvatar));
  //   patientDetails
  //     &&
  //     patientDetails.gender === "F"
  //     ?
  //     localStorage.setItem("getDataFrom", JSON.stringify("completePackageDataForFemale"))
  //     :
  //     localStorage.setItem("getDataFrom", JSON.stringify("completePackageData"))
  // });

  const submitHandler = (e) => {

    e.preventDefault();
    setToggleGender(false);

    setName(name)
    setAge(age)
    setPatientEmail(patientEmail)
    setGender(gender)
    setDate(date)
    setEmpId(empId)
    setLabId(labId)
    setBmi(bmi)
    setBp(bp)

    localStorage.setItem("patientDetails", JSON.stringify({
      "name": name,
      "age": age,
      "patientEmail": patientEmail,
      "gender": gender,
      "date": date,
      "empId": empId,
      "labId": labId,
      "bmi": bmi,
      "bp": bp,
    }));

    if (patientEmail.indexOf("@") !== -1 || patientEmail === "") {
      setNextPage(false);
    } else {
      alert("Invalid Email Provided!")
    }
  };

  // refill the values
  useEffect(() => {

    const myData = JSON.parse(localStorage.getItem("patientDetails"))

    if (myData) {
      setName(myData.name)
      setAge(myData.age)
      setGender(myData.gender)
      setDate(myData.date)
      setLabId(myData.labId)
      setEmpId(myData.empId)
      setBmi(myData.bmi)
      setBp(myData.bp)
      setPatientEmail(myData.patientEmail)
    }

  }, [])

  const randomAvatarHandler = () => {
    setToggleAvatar(true);
    if (count > 5) {
      setCount(0);
    } else {
      setCount(count + 1);
    }
  };

  const resetDataHandler = () => {
    setPatientDetails({
      name: "",
      age: "",
      gender: "",
      empId: "",
      labId: "",
      bmi: "",
      bp: "",
      date: "",
    });
    localStorage.removeItem("valueForAvatar");
    localStorage.removeItem("page1Data");
    localStorage.removeItem("currentTestData");
    handleClose();
    window.location.reload()
  };

  const dateHandler1 = (theDate) => {

    setDate(theDate)
  }

  console.log("thename:", name)


  return (
    <div className="card p-4" style={{ backgroundColor: "#e6f2ff" }}>
      {/* Modal Start*/}
      <div>
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>
                <i
                  style={{ color: "#e6e600" }}
                  className="fas fa-exclamation-triangle"
                ></i>{" "}
                Reset Confirmation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Resetting your patient information will erase all the data of the
              patient, including their tests. Are you sure you want to continue?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger btn-sm" onClick={resetDataHandler}>
                Confirm Reset
              </Button>
              <Button variant="primary btn-sm" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>

      {/* Modal End */}

      <div className="text-center">
        {gender === "M" ? (
          <div>
            <Avatar
              style={{ width: "100px", height: "100px" }}
              avatarStyle="Circle"
              topType="ShortHairShortFlat"
              accessoriesType={accessoriesTypeList[valueForAvatar]}
              hairColor="Brown"
              facialHairType="Blank"
              clotheType={clotheList[valueForAvatar]}
              clotheColor="Blue03"
              eyeType="Default"
              eyebrowType="Default"
              mouthType="Smile"
              skinColor="Pale"
            />
          </div>
        ) : (
          <div>
            <Avatar
              style={{ width: "100px", height: "100px" }}
              avatarStyle="Circle"
              accessoriesType={accessoriesTypeList[valueForAvatar]}
              facialHairType="Blank"
              clotheType={clotheList[valueForAvatar]}
              clotheColor="Blue03"
              eyeType="Default"
              mouthType="Default"
              skinColor="Pale"
            />
          </div>
        )}
      </div>

      <div className="text-center my-2">
        <button
          title="change"
          className="btn btn-info btn-sm button-focus-css mx-1"
          onClick={() => {
            randomAvatarHandler();
            setValueForAvatar(count);
          }}
        >
          <i className="fas fa-exchange-alt"></i>
        </button>

        <button
          title="default"
          className="btn btn-info btn-sm button-focus-css mx-1"
          onClick={() => {
            setToggleAvatar(false);
            setValueForAvatar(0);
          }}
        >
          <i className="fas fa-undo-alt"></i>
        </button>
      </div>

      <Form onChange={() => setNextPage(true)} onSubmit={submitHandler}>

        <div className="row">
          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Patient's Name</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="name"
              defaultValue={name}
              onChange={(e) => {                
                setName(e.target.value)
              }}
            />
          </Form.Group>

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Age</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="age"
              defaultValue={age}
              onChange={(e) => {
                setAge(e.target.value)
              }}
            />
          </Form.Group>

          <Form.Group className="col mb-3">
            <Form.Label><b>{"Patient Email"}</b></Form.Label>
            <Form.Control
              type="text"
              placeholder="email"
              defaultValue={patientEmail}
              onChange={(e) => {
                setPatientEmail(e.target.value)
              }}
            />
          </Form.Group>

          <Form.Group controlId="Radiogender" className="col mb-3">
            <Form.Label>
              <b>Gender:</b>
            </Form.Label>
            <span style={{ display: "flex" }}>
              <span style={{ marginTop: "5px" }}>Male</span>
              <span
                style={{
                  display: "flex",
                  marginLeft: "-15px",
                  marginRight: "22px",
                  marginTop: "5px",
                }}
              >
                <span onClick={() => setToggleGender(true)}>
                  <Form.Check id="radioMale">
                    <Form.Check
                      name="gender"
                      defaultValue="M"
                      type="radio"
                      checked={
                        gender !== "M" && gender !== "F"
                          ? patientDetails.gender === "M"
                          : toggleGender
                            ? gender === "M"
                            : patientDetails.gender === "M"
                      }
                      onChange={() => {
                        setGender("M")
                      }}
                    />
                  </Form.Check>
                </span>
              </span>

              <span style={{ marginTop: "5px" }}>Female</span>
              <span
                style={{ display: "flex", marginLeft: "-15px", marginTop: "5px" }}
              >
                <span onClick={() => setToggleGender(true)}>
                  <Form.Check id="radioFemale">
                    <Form.Check
                      name="gender"
                      defaultValue="F"
                      type="radio"
                      checked={
                        gender !== "M" && gender !== "F"
                          ? patientDetails.gender === "F"
                          : toggleGender
                            ? gender === "F"
                            : patientDetails.gender === "F"
                      }
                      onChange={() => {
                        setGender("F")
                      }}
                    />
                  </Form.Check>
                </span>
              </span>
            </span>
          </Form.Group>

        </div>

        <div className="row">

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>
                {"EmpId"}
              </b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Emp Id"
              defaultValue={empId}
              onChange={(e) => {
                setEmpId(e.target.value)
              }}
            />
          </Form.Group>

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>
                {"LabId"}
              </b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Lab Id"
              defaultValue={labId}
              onChange={(e) => {
                setLabId(e.target.value)
              }}
            />
          </Form.Group>

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>
                {"BMI"}
              </b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="bmi"
              defaultValue={bmi}
              onChange={(e) => {
                setBmi(e.target.value)
              }}
            />
          </Form.Group>
          <Form.Group className="col mb-3">
            <Form.Label>
              <b>
                {"BP"}
              </b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="bp"
              defaultValue={bp}
              onChange={(e) => {
                setBp(e.target.value)
              }}
            />
          </Form.Group>

          <Form.Group className="col mb-3">
            <Form.Label>
              <b>Registered Date</b>
            </Form.Label>
            <div className="d-flex">
              <Form.Control
                type="date"
                placeholder=""
                defaultValue={date}
                onChange={(e) => {
                  dateHandler1(e.target.value)
                }}
              />
            </div>
          </Form.Group>

        </div>

        <Link
          className="button-float-css"
          to={"/itemSelection/"}
          style={{ pointerEvents: nextPage === true ? "none" : "" }}
        >
          <button
            disabled={nextPage === true}
            className="btn btn-info btn-sm mt-3"
          >
            Next Page <i className="fas fa-arrow-alt-circle-right"></i>
          </button>
        </Link>

        <button
          type="submit"
          className="button-float-css btn btn-primary btn-sm mx-2 mt-3"
        >
          Save
        </button>

        <button
          onClick={handleShow}
          className="btn btn-outline-danger btn-sm mt-3 button-focus-css"
        >
          <i className="fas fa-undo-alt"></i> Reset User
        </button>
      </Form>
    </div>
  );
};

export default HomePage;
