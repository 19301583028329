// NOTE: when adding a new package make sure to remove the slice method from stateOptions 
// in itemSelectionScreen.js, REASON => avoiding "Package with all profiles" selection to appear in the page.

// male ref ranges
export const completePackageData = [
  {
    packageName: "Corporate Package",
    results: [
      {
        profilename: "Complete Blood Counts",
        investigation: [
          {
            "name": "Total Leukocyte Count",
            "low": "",
            "high": "",
            "unit": "Cells/cumm",
            "value": "",
            "method": ""
          },
          {
            "name": "RBC count",
            "low": "",
            "high": "",
            "unit": "Millions/cumm",
            "value": "",
            "method": ""
          },
          {
            "name": "Platelet Count",
            "low": "",
            "high": "",
            "unit": "lakhs/cumm",
            "value": "",
            "method": ""
          },
          {
            "name": "Polymorphs",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Lymphocytes",
            "low": "",
            "high": "",
            "unit": "%",
            "value": "",
            "method": ""
          },
          {
            "name": "Eosinophils",
            "low": "",
            "high": "",
            "unit": "%",
            "value": "",
            "method": ""
          },
          {
            "name": "Monocytes",
            "low": "",
            "high": "",
            "unit": "%",
            "value": "",
            "method": ""
          },
          {
            "name": "Basophils",
            "low": "",
            "high": "",
            "unit": "%",
            "value": "",
            "method": ""
          },
          {
            "name": "ESR",
            "low": "",
            "high": "",
            "unit": "mm",
            "value": "",
            "method": ""
          },
          
        ]
      },
      {
        profilename: "Anemia Studies",
        investigation: [
          {
            "name": "Haemoglobin",
            "low": "",
            "high": "",
            "unit": "gms%",
            "value": "",
            "method": ""
          },
          {
            "name": "Haematocrit",
            "low": "",
            "high": "",
            "unit": "%",
            "value": "",
            "method": ""
          },
          {
            "name": "MCV",
            "low": "",
            "high": "",
            "unit": "fl",
            "value": "",
            "method": ""
          },
          {
            "name": "MCH",
            "low": "",
            "high": "",
            "unit": "pg",
            "value": "",
            "method": ""
          },
          {
            "name": "MCHC",
            "low": "",
            "high": "",
            "unit": "%",
            "value": "",
            "method": ""
          },
          {
            "name": "RDW-CV",
            "low": "",
            "high": "",
            "unit": "%",
            "value": "",
            "method": ""
          },                 
        ]
      },
      {
        profilename: "Diabetes Monitoring",
        investigation: [          
          {
            "name": "Blood Sugar (Random)",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "HbA1c (Glycosylated Haemoglobin)",
            "low": "",
            "high": "",
            "unit": "%",
            "value": "",
            "method": ""
          },
          {
            "name": "eAG (Estimated Average Glucose)",
            "low": "",
            "high": "",
            "unit": "ng/mL",
            "value": "",
            "method": ""
          },
          {
            "name": "Glucose in Urine",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Kidney Profile",
        investigation: [          
          {
            "name": "Blood Urea Nitrogen (BUN)",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "Blood Urea",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "Serum Creatinine",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "Uric Acid",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Lipid Profile",
        investigation: [          
          {
            "name": "Total Cholesterol",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "Triglycerides",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "HDL Cholesterol",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "LDL Cholesterol",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "VLDL",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "Total Cholesterol : HDL ratio",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Liver Profile",
        investigation: [          
          {
            "name": "Total Bilirubin",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "Direct Bilirubin",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "Indirect Bilirubin",
            "low": "",
            "high": "",
            "unit": "mg/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "SGOT (AST)",
            "low": "",
            "high": "",
            "unit": "U/L",
            "value": "",
            "method": ""
          },
          {
            "name": "SGPT (ALT)",
            "low": "",
            "high": "",
            "unit": "U/L",
            "value": "",
            "method": ""
          },
          {
            "name": "ALP",
            "low": "",
            "high": "",
            "unit": "U/L",
            "value": "",
            "method": ""
          },
          {
            "name": "GGT",
            "low": "",
            "high": "",
            "unit": "U/L",
            "value": "",
            "method": ""
          },
          {
            "name": "Protein (Total)",
            "low": "",
            "high": "",
            "unit": "g/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "Albumin",
            "low": "",
            "high": "",
            "unit": "g/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "Globulin",
            "low": "",
            "high": "",
            "unit": "gm/dL",
            "value": "",
            "method": ""
          },
          {
            "name": "Albumin : Globulin ratio",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Urinalysis Profile",
        investigation: [          
          {
            "name": "Urine Colour",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Appearance",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "pH",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Specific Gravity",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Protein",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Pus Cells",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Epithelial Cells",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "RBC",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Casts",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Crystals",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Other compounds in Urine",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
        ]
      },
    ],
  },
];


// female ref ranges
export const completePackageDataForFemale = [
  {
    packageName: "Corporate Package",
    results: [
      {
        profilename: "Complete Blood Counts",
        investigation: [
          {
            "name": "Total Leukocyte Count",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "RBC count",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Platelet Count",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Polymorphs",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Lymphocytes",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Eosinophils",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Monocytes",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Basophils",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "ESR",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          
        ]
      },
      {
        profilename: "Anemia Studies",
        investigation: [
          {
            "name": "Haemoglobin",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Haematocrit",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "MCV",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "MCH",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "MCHC",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "RDW-CV",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },                 
        ]
      },
      {
        profilename: "Diabetes Monitoring",
        investigation: [          
          {
            "name": "Blood Sugar (Random)",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "HbA1c (Glycosylated Haemoglobin)",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "eAG (Estimated Average Glucose)",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Glucose in Urine",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Kidney Profile",
        investigation: [          
          {
            "name": "Blood Urea Nitrogen (BUN)",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Blood Urea",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Serum Creatinine",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Uric Acid",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Lipid Profile",
        investigation: [          
          {
            "name": "Total Cholesterol",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Triglycerides",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "HDL Cholesterol",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "LDL Cholesterol",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "VLDL",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Total Cholesterol : HDL ratio",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Liver Profile",
        investigation: [          
          {
            "name": "Total Bilirubin",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Direct Bilirubin",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Indirect Bilirubin",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "SGOT (AST)",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "SGPT (ALT)",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "ALP",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "GGT",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Protein (Total)",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Albumin",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Globulin",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Albumin : Globulin ratio",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
        ]
      },
      {
        profilename: "Urinalysis Profile",
        investigation: [          
          {
            "name": "Urine Colour",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Appearance",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "pH",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Specific Gravity",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Protein",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Pus Cells",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Epithelial Cells",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "RBC",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Casts",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Crystals",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
          {
            "name": "Other compounds in Urine",
            "low": "",
            "high": "",
            "unit": "",
            "value": "",
            "method": ""
          },
        ]
      },
    ],
  },
]