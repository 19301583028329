import React, { useState, useEffect } from 'react';
import { FileDrop } from 'react-file-drop';
import axios from 'axios'
import { Table, ProgressBar } from 'react-bootstrap';
import JsZip from 'jszip';
import { Alert } from 'react-bootstrap'
import { saveAs } from 'file-saver';
import Resizer from "react-image-file-resizer";

const DragDropCsv = () => {

    const [myFile, setMyFile] = useState([])
    const [myImage, setMyImage] = useState([])
    const [error, setError] = useState("")
    const [chooseFile, setChooseFile] = useState(false)
    const [chooseImage, setChooseImage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [collectedAdvices, setCollectedAdvices] = useState([])
    const [pdfStatus, setPdfStatus] = useState(false)
    const [pdfLinks, setPdfLinks] = useState([])
    const [showZipButton, setShowZipButton] = useState(false)
    const [count, setCount] = useState(0)
    const [percentage, setPercentage] = useState(0);
    const [progressBarAnimation, setProgressBarAnimation] = useState(true);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [dataHolder, setDataHolder] = useState([])
    const [imageBase64, setImageBase64] = useState("");
    const [imageCheckStatus, setImageCheckStatus] = useState(false)

    let historicalCase = false

    const styles = {
        border: '3px dashed grey',
        width: "100%",
        color: 'black',
        padding: 20,
        backgroundColor: "#e6f7ff"
    };


    const base64Converter = (imageFile) => {
        try {
            Resizer.imageFileResizer(
                imageFile,
                300,
                300,
                "JPEG",
                100,
                0,
                (uri) => {
                    console.log(uri);
                    setImageBase64(uri);
                },
                "base64",
                200,
                200
            );
            setImageCheckStatus(true)
        } catch (err) {
            console.log(err);
        }
    }

    const getBase64 = async () => {
        if (myImage[0] === undefined || myImage[0] === "") {
            setImageBase64("")
            setError("Empty Image File!")
        } else {
            let currentFileType = myImage[0].type;
            console.log("currentFileType: ", currentFileType);
            let checkPng = currentFileType.indexOf("png");
            let checkJpeg = currentFileType.indexOf("jpeg");
            let checkJpg = currentFileType.indexOf("jpg");

            if (checkPng !== -1 || checkJpeg !== -1 || checkJpg !== -1) {
                base64Converter(myImage[0])
                console.log("your base64 data\n", imageBase64)
            } else {
                setError("Invalid Image!")
                setLoading(false)
            }
        }
    };


    let requiredAdvicesArray = []

    const csvHandler = (csv) => {

        let lines = csv.split("\n");
        let result = [];
        let headers = lines[0].replace(/\s/g, "").split(",")
        //let headers = lines[0].replace(/\//g, "").replace(/\./g, "").replace(/\'/g, "").replace(/\&/g, "").replace(/\-/g, "").replace(/\s/g, "").split(",")

        console.log("headers", headers)

        for (let i = 1; i < lines.length; i++) {

            let obj = {};

            let modifiedLine = lines[i].replace(/"(.*?)"/g, (x) => x.replace(/,/g, "^"))

            console.log("modifiedLine \n\n", modifiedLine)

            // let splitForAdvice = modifyLine.split("Yes to work,")
            // //console.log("splitForAdvice \n\n", splitForAdvice[1])

            // requiredAdvicesArray.push(splitForAdvice[1])

            let currentline = modifiedLine.split(",");

            for (let j = 0; j < headers.length; j++) {
                // console.log("object created: ", obj[headers[j]], currentline[j])
                obj[headers[j]] = currentline[j];
            }

            if (obj.PatientName === "") {
                // skip
            } else {
                console.log("result is: ", result)
                result.push(obj);
            }
        }
        console.log("requiredAdvicesArray", requiredAdvicesArray, requiredAdvicesArray.length)
        setCollectedAdvices(requiredAdvicesArray)
        //let json = JSON.stringify(result)   
        console.log("array data", result)
        //setUserAllInfo(result)
        return result; //JSON
    }

    const fileHandler = () => {
        try {
            console.log("my file", myFile)
            let files = myFile
            console.log("the file type: \n", files[0].type)
            const fileType = files[0].type
            console.log("fileType \n", fileType)

            let checkExcel = fileType.indexOf("excel").toString();
            let checkCsv = fileType.indexOf("csv").toString();

            console.log("checkExcel: ", checkExcel)
            console.log("checkCsv: ", checkCsv)

            if (checkExcel !== "-1" || checkCsv !== "-1") {

                const formData = new FormData()
                formData.append('files[]', files[0])
                const reader = new FileReader()
                reader.onload = function (e) {
                    let contents = e.target.result

                    const csvGeneratedData = csvHandler(contents)
                    //console.log("csvGeneratedData", csvGeneratedData)
                    csvDataHandler(csvGeneratedData)
                }
                reader.readAsText(files[0])

            } else {
                console.log("failed on checkExcel", checkExcel, typeof (checkExcel))
                setError("Invalid File, only CSV's are accepted!")
                setLoading(false)
            }
        } catch (error) {
            setPdfLinks([])
            setError("CSVFile Error!")
            setLoading(false)
            console.log("error", error)
        }
    }

    //let myAllData = []
    let dataCollection = []
    let cleanArrayObjects = []
    let emailIsValid = true
    let nameOfPatient = ""

    const csvDataHandler = (ArrayObjects) => {

        // a remove method for javascript arrays
        // Array.prototype.remove = function (from, to) {
        //   var rest = this.slice((to || from) + 1 || this.length);
        //   this.length = from < 0 ? this.length + from : from;
        //   return this.push.apply(this, rest);
        // };

        ArrayObjects.map(each => each.EMPID === undefined && each.TestDate === undefined ? "" : cleanArrayObjects.push(each))
        console.log("array of objects recieved: ", cleanArrayObjects)

        let collectionOfObjStructure = []
        let requiredObjStructure = ""


        for (let i = 0; i < cleanArrayObjects.length; i++) {

            if (cleanArrayObjects[i].EMPID !== "" && cleanArrayObjects[i].TestDate !== "") {
                //console.log("looping in this obj\n", cleanArrayObjects[i])

                if (cleanArrayObjects[i].PatientEmail === undefined || cleanArrayObjects[i].PatientEmail === "" || cleanArrayObjects[i].PatientEmail.indexOf("@") !== -1) {
                    //
                } else {
                    emailIsValid = false
                    nameOfPatient = cleanArrayObjects[i].NAME
                }

                console.log("Expected: ", cleanArrayObjects[i].PatientEmail, cleanArrayObjects[i].NAME)

                requiredObjStructure = {
                    "LISID": cleanArrayObjects[i].LISID,
                    "EMPID": cleanArrayObjects[i].EMPID,
                    "NAME": cleanArrayObjects[i].NAME,
                    "GENDER": cleanArrayObjects[i].GENDER,
                    "AGE": cleanArrayObjects[i].AGE,
                    "Date": cleanArrayObjects[i].Date,
                    "BMI": cleanArrayObjects[i].BMI,
                    "BP": cleanArrayObjects[i].BP,
                    "patientEmail": cleanArrayObjects[i].PatientEmail,
                    "observations": []
                }

                let copyOfTestDate = cleanArrayObjects[i].TestDate

                let currentObj = cleanArrayObjects[i]
                delete currentObj["LISID"]
                delete currentObj["EMPID"]
                delete currentObj["NAME"]
                delete currentObj["GENDER"]
                delete currentObj["AGE"]
                delete currentObj["Date"]
                delete currentObj["TestDate"]
                delete currentObj["BMI"]
                delete currentObj["BP"]
                delete currentObj["PatientEmail"]


                //console.log("you new cleaned object:", currentObj)

                for (let [key, value] of Object.entries(currentObj)) {
                    //console.log(`${key}: ${value}`);
                    let objForObservations = {
                        "name": key,
                        "value": value.replaceAll("\r", ""),
                        "observation_time": copyOfTestDate,
                        "pastObservation": []
                    }
                    requiredObjStructure.observations.push(objForObservations)
                }
                collectionOfObjStructure.push(requiredObjStructure)
            }

            else if (cleanArrayObjects[i].EMPID === "" && cleanArrayObjects[i].TestDate !== "") {

                if (cleanArrayObjects[i].PatientEmail === "" || cleanArrayObjects[i].PatientEmail.indexOf("@") !== -1) {
                    //
                } else {
                    emailIsValid = false
                    nameOfPatient = cleanArrayObjects[i].EMPID
                }

                let cloneTestDate = cleanArrayObjects[i].TestDate

                let thisCurrentObj = cleanArrayObjects[i]

                delete thisCurrentObj["LISID"]
                delete thisCurrentObj["EMPID"]
                delete thisCurrentObj["NAME"]
                delete thisCurrentObj["GENDER"]
                delete thisCurrentObj["AGE"]
                delete thisCurrentObj["Date"]
                delete thisCurrentObj["TestDate"]
                delete thisCurrentObj["BMI"]
                delete thisCurrentObj["BP"]
                delete thisCurrentObj["PatientEmail"]

                let updatePreviousObj = collectionOfObjStructure.length - 1

                for (let [key, value] of Object.entries(thisCurrentObj)) {
                    let newTestForObs = {
                        "name": key,
                        "value": value.replaceAll("\r", ""),
                        "observation_time": cloneTestDate
                    }
                    let prevObjObservations = collectionOfObjStructure[updatePreviousObj].observations
                    for (let p = 0; p < prevObjObservations.length; p++) {
                        if (prevObjObservations[p].name === newTestForObs.name) {
                            if (newTestForObs.value !== "") {
                                prevObjObservations[p].pastObservation.push(newTestForObs)
                            }
                            console.log("match found put it in this test", prevObjObservations[p])
                            console.log("push this test\n", newTestForObs)
                        }
                    }
                }
            }
        }

        dataCollection = collectionOfObjStructure
        setDataHolder(collectionOfObjStructure) // final json format

        // check email validation
        if (emailIsValid) {
            sendForSmartReport()
        } else {
            setLoading(false)
            alert(`Invalid Email Found! Patient Name: ${nameOfPatient}`)
        }
    }

    console.log("the data holder\n", dataHolder)

    useEffect(() => {
        let value1 = dataHolder.length
        let value2 = count

        let thePercentage = value2 / value1 * 100
        setPercentage(thePercentage)

    }, [count])

    useEffect(() => {
        if (percentage === 100) {
            setShowZipButton(true)
            setProgressBarAnimation(false)
        }
    }, [percentage])

    let myCurrentValue = 0
    let myArray = []

    const sendForSmartReport = async () => {
        setShowProgressBar(true)
        console.log("at this point the data is\n", dataCollection)

        // checking for past observations (need to check only once for all the items present in dataCollection)
        if (myCurrentValue === 0) {
            for (let x = 0; x < dataCollection.length; x++) {
                let firstObjObs = dataCollection[x].observations
                for (let y = 0; y < firstObjObs.length; y++) {
                    let thisObservationPastData = firstObjObs[y].pastObservation
                    if (thisObservationPastData.length > 0) {
                        historicalCase = true
                    }
                }
            }
        }

        let dataToSend = dataCollection[myCurrentValue]

        // let imageBase64Data = imageBase64 === "" || imageBase64 === undefined ? "" : imageBase64
        // console.log("imageBase64Data at this point", imageBase64Data)

        if (dataToSend === undefined) {
            //
        } else {
            if (historicalCase) {
                dataToSend["hasPastData"] = true
                dataToSend["brandLogoBase64"] = imageBase64
            } else {
                dataToSend["hasPastData"] = false
                dataToSend["brandLogoBase64"] = imageBase64
            }
        }

        console.log("Data to send", dataToSend)

        var config = {
            method: 'POST',
            // url: '',
            url: 'https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/wellcorp',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dataToSend
            //data: dummyData
        };
        console.log("what im sending\n", dataToSend)

        try {
            setChooseImage(false)
            setChooseFile(false)
            const axiosData = await axios(config)
            console.log("inside axiosdata ===> \n\n", axiosData)
            myArray.push(axiosData.data.PdfData)
            setCount(myArray.length)
            setPdfLinks(myArray)
            setLoading(false)
            setLoading(false)
            if (myCurrentValue === dataCollection.length) {
                // nothing
                setLoading(false)
                setError("")
            } else {
                myCurrentValue = myCurrentValue + 1
                setLoading(true)
                sendForSmartReport()
            }

        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const downloadPDF = (myPdfDoc, emp_id, emp_name) => {
        console.log("the file getting downloaded")
        const linkSource = `data:application/pdf;base64,${myPdfDoc}`;
        const downloadLink = document.createElement("a");
        const fileName = `${emp_id}-${emp_name}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setPdfStatus(true)
    }

    const downloadZip = () => {
        console.log("your array\n", pdfLinks)

        var zip = new JsZip();
        let allFiles = zip.folder('myfiles')

        for (let i = 0; i < pdfLinks.length; i++) {
            allFiles.file(`${dataHolder[i].EMPID + '-' + dataHolder[i].NAME}.pdf`, pdfLinks[i], { base64: true });
        }

        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                // see FileSaver.js
                saveAs(content, "SmartReports.zip");
            });
    }



    console.log("pdf links", pdfLinks)
    console.log("imageBase64\n", imageBase64)

    return (
        <div>
            {error ?
                <Alert variant="danger">
                    {error}
                </Alert>
                : ""}
            <div style={styles}>
                <FileDrop
                    onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                    onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                    onFrameDrop={(event) => console.log('onFrameDrop', event)}
                    onDragOver={(event) => console.log('onDragOver', event)}
                    onDragLeave={(event) => console.log('onDragLeave', event)}
                    onDrop={(files, event) => {
                        console.log('onDrop!', files, event)
                        setMyFile(files)
                        setDataHolder([])
                        setShowProgressBar(false)
                        setPercentage(0)
                        setCount(0)
                        setLoading(false)
                        setError("")
                        setPdfLinks([])
                    }}
                >
                    {myFile.length > 0 ?
                        <span>
                            <i className="fas fa-file-alt fa-3x text-primary mb-1"></i>
                            <div style={{ fontSize: "1.1rem" }}>
                                <b>File Recieved: </b> {myFile[0].name}
                            </div>
                        </span>
                        :
                        <div>
                            <span><i className="fas fa-cloud-upload-alt fa-3x text-primary"></i></span>
                            <div style={{ fontSize: "1.1rem", fontWeight: "600" }}>{"Drag & Drop your CSV file here"}</div>
                        </div>
                    }
                </FileDrop>
            </div>

            {chooseFile ?
                <div style={{ border: "2px solid #9a9ea0", marginTop: "12px", padding: "8px", backgroundColor: "#e6f7ff" }}>
                    <b>Select CSV</b> <i className="far fa-file-excel"></i>
                    <input
                        className="form-control mt-3"
                        id="fileupload"
                        type="file"
                        onChange={(e) => {
                            setMyFile(e.target.files)
                            setError("")
                            setLoading(false)
                        }}
                    />
                </div>
                :
                null
            }


            {chooseImage ?
                <div style={{ border: "2px solid #9a9ea0", marginTop: "12px", padding: "8px", backgroundColor: "#e6f7ff" }}>
                    <div className='row'>
                        <div className='col'><b>Select Image</b> <i className="far fa-image"></i></div>
                        <div>
                            <input
                                className="form-control mt-3"
                                id="imageUpload"
                                type="file"
                                onChange={(e) => {
                                    setMyImage(e.target.files)
                                    setError("")
                                    setLoading(false)
                                }}
                            />
                            <button className="btn btn-sm btn-info mt-3" onClick={getBase64}>Save Image</button>
                        </div>
                    </div>
                    {/* <div style={{ marginTop: "18px", marginLeft: "10px" }} className="col">
                    <button className="btn btn-info btn-sm mt-3 d-flex justify-content-left" onClick={getBase64}>Save Image</button>
                </div> */}
                </div>
                :
                null
            }


            <div className="mt-3"><b>Image Status</b> {imageCheckStatus ? <i className="far fa-check-circle text-primary"></i> : "[Not Recieved]"} </div>


            <div className="d-flex my-3">

                <button className="btn btn-info btn-sm button-focus-css" onClick={() => setChooseFile(!chooseFile)}>
                    {chooseFile ?
                        <span>Close CSV Selection <i className="far fa-times-circle"></i></span>
                        :
                        <span>Choose CSV <i className="far fa-file-excel"></i></span>
                    }
                </button>

                <button className="btn mx-3 btn-info btn-sm button-focus-css" onClick={() => setChooseImage(!chooseImage)}>
                    {chooseImage ?
                        <span>Close Image Selection <i className="far fa-times-circle"></i></span>
                        :
                        <span>Choose Image <i className="far fa-image"></i></span>
                    }
                </button>

                {loading && error === "" ?

                    <span>
                        <button className="btn btn-success btn-sm" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {" "}Getting Links...
                        </button>
                    </span>
                    :
                    <button
                        className="btn btn-success btn-sm button-focus-css"
                        onClick={() => {
                            fileHandler()
                            setLoading(true)
                        }}
                    >
                        Generate Reports <i class="far fa-file-pdf"></i>
                    </button>
                }

                <button
                    title="refresh"
                    onClick={() => window.location.reload()}
                    className="btn btn-info btn-sm mx-3"
                    hidden={percentage !== 100}
                >
                    <i class="fas fa-redo"></i>
                </button>


                {/* {pdfStatus ? <span>
                    Smart Report Downloaded <i className="far fa-check-circle text-primary"></i>
                </span>
                    : ""} */}

            </div>

            <div
                hidden={!showProgressBar}
                style={{
                    marginBottom: "10px",
                    backgroundColor: "#d9d9d9",
                    borderRadius: "12px",
                    padding: "8px 8px 0px 8px"
                }}
            >
                <div>
                    <span>
                        Progress {count} {"/"} {dataHolder.length}
                    </span>
                    {percentage === 100 ?
                        <span onClick={() => setShowProgressBar(false)} style={{ position: "relative", float: "right" }}>
                            <i className="fas fa-times mx-2 icon-button-css" title="close"></i></span>
                        : null
                    }
                </div>
                <div style={{ paddingBottom: "10px" }}>
                    <ProgressBar animated={progressBarAnimation} now={percentage} />
                </div>
            </div>


            <div>
                {pdfLinks.length === 0 || pdfLinks === undefined ?
                    ""
                    :
                    // <span
                    //     className="button-hover-css text-primary"
                    //     onClick={() => downloadPDF(pdfLinks)}
                    //     style={{ marginTop: "18px" }}
                    // >
                    //     Donwload PDF
                    // </span>
                    <div>
                        <Table striped bordered className="text-center">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Emp Id</th>
                                    <th>Emp Name</th>
                                    <th>
                                        Download Link
                                        {showZipButton ?
                                            <span
                                                style={{ marginLeft: "8px" }}
                                                className="btn btn-outline-success btn-sm button-focus-css"
                                                onClick={downloadZip}>Zip all Reports <i class="far fa-file-archive"></i></span>
                                            : ""}
                                    </th>
                                </tr>
                            </thead>

                            {pdfLinks.map((eachRecord, idx) => (
                                <tbody>
                                    <tr key={idx + 1}>
                                        <td>{idx + 1}</td>
                                        <td>{dataHolder[idx].EMPID}</td>
                                        <td>{dataHolder[idx].NAME}</td>
                                        <td>
                                            <span
                                                className="button-hover-css text-primary"
                                                onClick={() => downloadPDF(eachRecord, dataHolder[idx].EMPID, dataHolder[idx].NAME)}
                                                style={{ marginTop: "18px" }}
                                            >
                                                download pdf <i className="fas fa-download"></i>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </Table>

                    </div>
                }
            </div>
        </div>
    )
}

export default DragDropCsv