import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'


const LoginPage = () => {

    const history = useHistory()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [credentialsError, setCredentialsError] = useState("")
    const [errorStatus, setErrorStatus] = useState(false)

    const submitHandler = (e) => {
        e.preventDefault()
        console.log("username", username)
        console.log("pass", password)
        userValidator()
    }

    const userValidator = () => {
        if(username === "wellCorp@admin" && password === "wellCorp@admin") {
            setErrorStatus(false)
            setCredentialsError("")
            let cred = "wellCorp@admin"
            localStorage.setItem("credentials", JSON.stringify(cred))
            history.push("/")
            window.location.reload()
        } else {
            setErrorStatus(true)
            setCredentialsError("*Invalid login details!")
            setTimeout(() => {
                setErrorStatus(false)
                setCredentialsError("")                    
            }, [3500]);
        }
    }


    return (
        <div>
            <Row className='justify-content-md-center'>
                <Col xs={12} md={6} className="shadow-lg p-3 mb-5 rounded">
                    <h1>Sign In</h1>
                    {errorStatus ? <span style={{ color: "red"}}>{"*Invalid Credentials"}</span> : ""}
                    <Form onSubmit={submitHandler}>
                        <Form.Group className="mb-3" controlId='username'>
                            <Form.Label>
                                Username
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="enter username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId='password'>
                            <Form.Label>
                                Password
                            </Form.Label>
                            <Form.Control
                                required
                                type="password"
                                placeholder="enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>

                        <Button type="submit" variant='primary my-3'>Sign In</Button>
                    </Form>

                </Col>
            </Row>
        </div>
    )
}

export default LoginPage
